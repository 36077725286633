import React, { Component } from "react";

class Resume extends Component {
  render() {
    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        return (
          <div key={education.school}>
            <h3>{education.school}</h3>
            <p className="info">
              {education.degree} <span>&bull;</span>
              <em className="date">{education.graduated}</em>
            </p>
            <p>{education.description.replace("\n", <br />)}</p>
          </div>
        );
      });
      var skills = this.props.data.skills.map(function (skills) {
        var className = "bar-expand " + skills.name.toLowerCase();
        return (
          <li key={skills.name}>
            <span style={{ width: skills.level }} className={className}></span>
            <em>{skills.name}</em>
          </li>
        );
      });
    }

    return (
      <section id="resume">
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Past work</span>
            </h1>
          </div>
          <div className="nine columns main-col">
            <p>
              Below are some of the larger projects my team and I have worked
              with. However, to protect my clients' business information, I am
              not able to reveal their identity. The information you see below
              are consented by project owners.
            </p>
            <div className="row item">
              <div className="twelve columns">
                <div>
                  <h3>Ultimate Loan Calculator/Credit Indicator</h3>
                  <p className="info">
                    Finance | Loan | Banking | Online Banking{" "}
                    <span>&bull;</span>
                    <em className="date">April 2019</em>
                  </p>
                  <p>
                    To fulfill our client's need, we arranged 4 weeks of
                    meetings, 3 days a week with their bankers to understand the
                    loaning business in Malaysia, including how banks evaluate
                    loan applicants by calculating DSR rate/gearing
                    ratio/armotization of mortgage loan and etc. At the end of
                    the project, we built a Wordpress site for their company and
                    a member-only portal (using React JS) where their users can:{" "}
                    <br />
                    <br /> &nbsp;&nbsp;1) Check DSR by entering a few key
                    information (Personal Loan) <br />
                    &nbsp;&nbsp;2) Check Gearing Ratio (Business Loan)
                    <br />
                    &nbsp;&nbsp;3) Purchase C-Cris report and make payment using
                    credit card
                    <br />
                    &nbsp;&nbsp;4) Schedule for consultation
                  </p>
                </div>
              </div>
            </div>
            <div className="row item">
              <div className="twelve columns">
                <div>
                  <h3>Itinerary Planner for Startup Travel Agency</h3>
                  <p className="info">
                    Google Map API | Cross-platform Mobile Apps{" "}
                    <span>&bull;</span>
                    <em className="date">Jan 2020</em>
                  </p>
                  <p>
                    When I was studying in Italy, I was fortunate enough to work
                    with a local travel agency who wanted to build an app on
                    both iOS and Android to provide their employees the
                    convenience of planning itinerary with their phone. To
                    address their needs, we built a cross-platform mobile
                    applications that has the following features:
                    <br />
                    <br /> &nbsp;&nbsp;1) Get tourist attraction recommedation
                    just by searching a place. (e.g. A hotel)
                    <br />
                    &nbsp;&nbsp;2) Plan itinerary with other colleagues
                    <br />
                    &nbsp;&nbsp;3) Optimize their travel route using Google Map
                    API (e.g. Find the best route to avoid redundant route and
                    etc.)
                    <br />
                    &nbsp;&nbsp;4) Read about an attraction directly within the
                    app. (Information provided by Wikipedia)
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="row item">
              <div className="twelve columns">
                <div>
                  <h3>Portals for Agent-only Commerce</h3>
                  <p className="info">
                    E-Commerce | Business | Data Structures | PWA
                    <span>&bull;</span>
                    <em className="date">July 2020</em>
                  </p>
                  <p>
                    One of our clients runs a MLM-like business whereby agents
                    must refer more agents to earn commission. To help smoothen
                    the process, we built an admin portal for them to manage
                    their products, members, orders, members' inventory, and a
                    member portal with the following main features:
                    <br />
                    <br /> &nbsp;&nbsp;1) Refer new agents and manage all
                    downline in one place <br />
                    &nbsp;&nbsp;2) Restock their inventory
                    <br />
                    &nbsp;&nbsp;3) Have direct access to company's resources
                    (e.g. Marketing posters, new product launching/features and
                    etc.)
                    <br />
                    &nbsp;&nbsp;4) Have their commission credited directly into
                    the cash wallet within the web app
                    <br />
                    <br />
                    The most challenging part of the project was trying to
                    understand the business requirement as their bonus scheme
                    was very complicated. Eventually we made it happen by
                    writing algorithms within the database using PostgreSQL.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>

          <div className="nine columns main-col">{work}</div>
        </div> */}
        <div id="skills" className="row skill education">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <p>{skillmessage}</p>

            <div className="bars">
              <ul className="skills">{skills}</ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
